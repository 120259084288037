<template>
  <div class="page-control">
    <div class="page-control-btns">
      <div class="page-btn" :class="disabledPrev ? 'btn-disabled' : ''" @click="prevPage">上一页</div>
      <div class="page-number" @click="showPagePopUp">
        <span>{{page}}</span>
        <span>/</span>
        <span>{{pageTotal}}</span>
      </div>
      <div class="page-btn" :class="disabledNext ? 'btn-disabled' : ''" @click="nextPage">下一页</div>
    </div>

    <div class="page-container" :hidden="!pageMask">
      <div class="page-mask" @click="hidePagePopUp"></div>
      <div class="page-popup">
        <div class="page-popup-box">
          <div
            class="page-line"
            :class="{current: p === page}"
            v-for="p in pageTotal"
            :key="p"
            @click="onChangePage(p)"
          >第{{p}}页</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "paginate",
  props: {
    page: {
      type: Number,
      default() {
        return 1;
      }
    },
    pageTotal: {
      type: Number
    }
  },
  data() {
    return {
      pageMask: false
    };
  },
  computed: {
    //判断按钮是否为disabled
    disabledNext() {
      return this.page >= this.pageTotal;
    },
    disabledPrev() {
      return this.page <= 1;
    }
  },
  methods: {
    //每次改变页码就调用该函数
    triggerParent(page) {
      console.log("page=", page);
      this.$emit("change", page);
      this.$emit("update:page", page);
    },
    //开启页码弹窗
    showPagePopUp() {
      this.pageMask = true;
    },
    //关闭页码弹窗
    hidePagePopUp() {
      this.pageMask = false;
    },
    //更改页码点击事件
    onChangePage(page) {
      if (page !== this.page) {
        this.pageMask = false;
        this.triggerParent(page);
      }
    },
    //上一页点击事件
    prevPage() {
      let { page } = this;
      if (page <= 1) return;
      this.triggerParent(page - 1);
    },
    //下一页点击事件
    nextPage() {
      let { page, pageTotal } = this;
      if (page >= pageTotal) return;
      this.triggerParent(page + 1);
    }
  }
};
</script>
  
<style scoped>
div,
span,
image {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.page-control {
  width: 100%;
  background-color: #ffffff;
}

.page-control .page-control-btns {
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.page-control .page-control-btns .page-number {
  width: 20%;
  text-align: center;
  color: #333;
}

.page-control .page-control-btns .page-number:active {
  background-color: #ddd;
}

.page-control .page-control-btns .page-btn {
  width: 30%;
  padding: 6px 8px;
  font-size: 18px;
  background-color: #2979ff;
  color: #fff;
  border-radius: 10px;
  text-align: center;
}

.page-control .page-control-btns .page-btn:active {
  opacity: 0.5;
}

.page-control .page-control-btns .btn-disabled {
  background-color: #ddd;
  color: #999;
}

.page-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.page-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.page-popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.page-popup-box {
  width: 60%;
  margin: 0 auto;
  background-color: #fff;
  max-height: 60%;
  border-radius: 10px;
  z-index: 9999;
  overflow: auto;
}

.page-line {
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0px 10px;
  text-align: center;
  border-bottom: 1px solid #e2e2e2;
}

.page-line.current {
  color: red;
}

.page-line:active {
  background-color: #ddd;
}
</style>