<template>
  <div class="page">
    <van-sticky>
      <van-search
        placeholder="请输入搜索关键词"
        shape="round"
        v-model="kw"
        readonly
        show-action
        @click="showSearch()"
        @cancel="onCancelSearch"
      ></van-search>
    </van-sticky>

    <van-row>
      <van-col class="nav-container">
        <div class="sidebar">
          <van-sidebar v-model="navIndex">
            <van-sidebar-item title="全部" @click.native="catChange(5)"></van-sidebar-item>
            <van-sidebar-item
              v-for="(nav, index) in navs"
              :key="index"
              :title="nav.name"
              @click.native="catChange(nav.id)"
            />
          </van-sidebar>
        </div>
      </van-col>
      <van-col class="list-container">
        <goods-list :list="list" />
        <div>
          <Pagination :page-total="pageTotal" :page="page" @change="pageChanged" />
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import goodsList from "../components/goodsGrid.vue";
import Pagination from "../components/paginate/paginate.vue";
import Vue from "vue";
import { Search, Sticky, Popup, Sidebar, SidebarItem } from "vant";
Vue.use(Sticky)
  .use(Search)
  .use(Sidebar)
  .use(SidebarItem)
  .use(Popup);

export default {
  name: "searchResult",
  components: {
    goodsList,
    Pagination
  },
  data() {
    return {
      isReady: false,
      searchMsg: "",
      pageTotal: 0,
      searchVisible: false,
      isLoading: false,
      lastPath: "",
      list: [],
      navIndex: 0,
      cid: 5,
      kw: "",
      size: 20,
      page: 1
    };
  },
  computed: {
    navs() {
      return this.$store.state.catTree || [];
    },
    currentPath() {
      return this.$route.fullPath;
    },
    pageKey() {
      let q = this.$route.query;
      return `search-${q.cid}-${q.kw}-${q.page}`;
    }
  },
  methods: {
    getData() {
      if (this.isLoading) return;
      this.isLoading = true;
      this.getRouteQuery();
      let { size, page, kw, cid } = this;
      const params = { size, page, kw, cid };
      this.$http
        .get("list", params)
        .then(res => {
          this.makeData(res);
        })
        .catch(err => {
          this.isLoading = false;
          this.lastPath = "";
          window.history.go(-1);
        });
    },
    makeData(res) {
      this.isLoading = false;
      this.isReady = true;
      if (res.code !== 0) {
        if (res.pageTitle) {
          this.$store.commit("setPageTitle", res.pageTitle);
          this.$toast(res.pageTitle);
        }
        const { data, last_page, current_page } = res.list;

        this.setNavIndex(this.cid);
        this.page = +current_page;
        this.pageTotal = +last_page;
        this.list = data;
      } else {
        this.searchMsg = (res && res.msg) || "";
      }
    },
    setNavIndex(cid) {
      this.navIndex = 0;
      for (let i = 0; i < this.navs.length; i++) {
        const nav = this.navs[i];
        if (nav.id === cid) {
          this.navIndex = i + 1;
        }
      }
    },
    catChange(cid) {
      this.cid = cid;
      this.page = 1;
      this.onSearch({ cid, page: 1 });
    },
    pageChanged: function(page) {
      this.page = page;
      this.onSearch({ page });
    },
    showSearch: function() {
      this.$router.push({ name: "search", query: { kw: this.kw } });
    },
    onCancelSearch: function() {
      this.page = 1;
      this.kw = "";
      this.onSearch({ page: 1, kw: "" });
    },
    onSearch: function(q) {
      this.searchVisible = false;
      let query = Object.assign({}, this.$route.query, q);
      this.$router.push({ name: "goodsList", query }).then(() => {
        this.getData();
      });
    },
    getRouteQuery() {
      const { cid, kw, page } = this.$route.query;
      this.page = +page || 1;
      this.cid = +cid || 5;
      this.kw = kw || "";
    }
  },
  created() {
    this.searchVisible = false;
    if (this.lastPath !== this.$route.fullPath) {
      this.getRouteQuery();
      this.lastPath = this.$route.fullPath;
      this.getData();
    }
  }
};
</script>

<style scoped>
.search-error {
  background-color: #fff;
  color: red;
  letter-spacing: 2px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 60px;
}

.nav-container {
  width: 80px;
}
.nav-container .sidebar {
  position: fixed;
  left: 0;
  top: 54px;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - 104px);
}
.list-container {
  width: calc(100% - 80px);
  padding-left: 1px;
  background-color: #f0f0f0;
}
</style>
